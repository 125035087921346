import Cookie from 'js-cookie';
import { CookieNames } from '@/constants/Cookies';

const domain = process.env.host || null;

export function getCookie(name) {
  return Cookie.get(name, { domain });
}

export function setCookie(name, value, options = {}) {
  const config = domain
    ? {
        domain,
        sameSite: 'Lax',
        secure: true,
        ...options,
      }
    : options;

  Cookie.set(name, value, config);
}

export function deleteCookie(name) {
  Cookie.remove(name, { domain });
}

export function deleteAccessTokenCookies() {
  deleteCookie(CookieNames.ACCESS_TOKEN);
  deleteCookie(CookieNames.REFRESH_TOKEN);
}
