import { ref, computed, watch } from '@nuxtjs/composition-api';
import useCurrentCompany from '@/hooks/useCurrentCompany';
import { config, Product, Vis } from './planConfig';
import type { PlanConfig, Feature } from './planConfig';
import useCurrentUser from '@/hooks/useCurrentUser';

import {
  useGetCompanySubscriptionsQuery,
  ProductKey,
  SubscriptionStatus,
  PricingPackageType,
  Subscription,
} from '@/gql/generated';

import { useApolloQuery } from '@/gql/apolloWrapper';

// ------------------------ //
//  Begin Functions
// ------------------------ //

interface PlanAccess {
  display: Vis;
  upsell?: string;
  showInNav: boolean;
  hasAccess: boolean;
  hideInNav: boolean;
  isUpsell: boolean;
  isUpcharge: boolean;
}

const mapPlanAccess = (
  config: PlanConfig<Feature>,
  isTierZero: boolean
): PlanConfig<PlanAccess> =>
  Object.entries(config).reduce((acc, [key, value]) => {
    acc[key] = {
      ...value,
      showInNav:
        (value.display === Vis.SHOW ||
          value.display === Vis.UPSELL ||
          value.display === Vis.UPCHARGE) &&
        (!value.hideForT0 || !isTierZero),
      hasAccess: value.display === Vis.SHOW,
      hideInNav: value.display === Vis.HIDE,
      isUpsell: value.display === Vis.UPSELL,
      isUpcharge: value.display === Vis.UPCHARGE,
    };

    return acc;
  }, {} as PlanConfig<PlanAccess>);

// ------------------------ //
//  End Functions
// ------------------------ //

let init;

const isReady = ref(false);
const { currentUser } = useCurrentUser();

const allActiveSubscriptions = ref<Subscription[]>([]);
const isLoading = ref(false);
const currentPlan = ref();

const checkForPackage = (products, productType: ProductKey) =>
  products.some(
    (s) =>
      s.productCode === productType &&
      (s.status === SubscriptionStatus.Active ||
        s.status === SubscriptionStatus.Trialing)
  );

const getTrialingStatus = (products: Subscription[] = []) =>
  products.some(
    (s) =>
      [ProductKey.Lite, ProductKey.Core, ProductKey.Plus].includes(
        s.productCode
      ) && s.status === SubscriptionStatus.Trialing
  );

const getPayrollStatus = (products: Subscription[] = []) =>
  products.some(
    (s) =>
      (s.productCode === ProductKey.Payroll ||
        s.productCode === ProductKey.Plus) &&
      (s.status === SubscriptionStatus.Active ||
        s.status === SubscriptionStatus.Trialing)
  );

let retryCount = 0;
const setupHook = () => {
  watch(
    currentUser,
    (user) => {
      const stripeCustomerId = user?._company.stripe?.customer?.id;
      if (stripeCustomerId) {
        const { onResult, onError } = useApolloQuery(
          useGetCompanySubscriptionsQuery,
          {
            data: {
              stripeCustomerId,
            },
          }
        );

        onError((errors) => {
          if (retryCount < 3 && !!errors.networkError?.message) {
            retryCount += 1;

            setupHook();
          }
        });

        onResult(({ companySubscriptions: res }) => {
          allActiveSubscriptions.value = res.filter(
            (s) =>
              s.status === SubscriptionStatus.Active ||
              s.status === SubscriptionStatus.Trialing
          ) as Subscription[];

          if (checkForPackage(res, ProductKey.Lite)) {
            currentPlan.value = ProductKey.Lite;
          }

          if (checkForPackage(res, ProductKey.Plus)) {
            currentPlan.value = ProductKey.Plus;
          }

          if (checkForPackage(res, ProductKey.Pause)) {
            currentPlan.value = ProductKey.Pause;
          }

          if (checkForPackage(res, ProductKey.Storage)) {
            currentPlan.value = ProductKey.Storage;
          }

          // Fallback plan for now.
          if (!currentPlan.value) {
            currentPlan.value = ProductKey.Core;
          }

          isReady.value = true;
        });
      }
    },
    { immediate: true }
  );
};

// This will eventually be delivered directly from the BE, for now we're pulling it from our local static config.
const currentPlanConfig = computed(() =>
  currentPlan.value ? config[currentPlan.value] : {}
);

// ------------------------ //
//  Plan helpers
// ------------------------ //

const isBambeeLite = computed(() => currentPlan.value === ProductKey.Lite);
const isBambeeBusiness = computed(() => currentPlan.value === ProductKey.Core);
const isBambeePlus = computed(() => currentPlan.value === ProductKey.Plus);
const isBambeePause = computed(() => currentPlan.value === ProductKey.Pause);
const isBambeeElite = computed(() => currentPlan.value === ProductKey.Elite);
const isBambeeStorage = computed(
  () => currentPlan.value === ProductKey.Storage
);

const hasPayroll = computed(() =>
  getPayrollStatus(allActiveSubscriptions.value)
);

const isTrial = computed(() => getTrialingStatus(allActiveSubscriptions.value));

const hasBasicAccess = computed(() => {
  return !(isBambeeLite.value || isBambeePause.value || isBambeeStorage.value);
});

// Replace with better method of grabbing tier when we replace the current query used in this file
const { company } = useCurrentCompany();
const isTierZero = computed(() => !!company.value.plan?.includes('t0'));

const planAccessMap = computed(() =>
  mapPlanAccess(currentPlanConfig.value, isTierZero.value)
);

const usePlanAccess = (force = false) => {
  if (!init || force) {
    setupHook();
    init = true;
  }

  return {
    isReady,
    isLoading,

    planAccessMap,
    currentPlan,

    isBambeeLite,
    isBambeeBusiness,
    isBambeePlus,
    isBambeeElite,
    isBambeePause,
    isBambeeStorage,
    isTrial,

    hasPayroll,
    hasBasicAccess,

    allActiveSubscriptions,
  };
};

export default usePlanAccess;
