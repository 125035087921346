<template>
  <div>
    <ErrorBoundary>
      <div class="relative flex flex-col min-h-screen lg:pl-56 xl:pl-64">
        <AnnularThrobber v-if="!currentUser" class="m-auto" />
        <template v-else>
          <GlobalDelinquentBanner />

          <TierUpgradeNoticeModal />
          <PausedAccountBanner />

          <CreditCardFailedModal title="Unable to complete purchase" />
          <TierUpgradeNoticeBanner
            v-if="shouldShowBanner"
            @open-modal="openModal"
          />

          <div v-else>
            <div v-if="shouldShowServiceUpdate && isBannerVisible">
              <ServiceUpdateBanner />
              <ServiceUpdateModal />
            </div>
          </div>
          <TheHeader
            class="sticky top-0 z-30 lg:relative"
            :is-nav-open="isNavOpen"
            @toggle-nav="isNavOpen = !isNavOpen"
          />
          <TheNav
            :is-nav-open="isNavOpen"
            class="fixed inset-y-0 left-0 z-40 w-64 lg:w-56 xl:w-64"
          />
          <div>
            <main
              id="main-content"
              class="flex flex-col flex-grow"
              :class="[
                fullBleed
                  ? 'max-w-full'
                  : 'px-4 pb-8 lg:px-6 main-content h-full',
              ]"
            >
              <CountdownBanner v-if="showCountdown" class="m-4" />
              <nuxt class="flex-grow" :class="{ 'pt-6': !fullBleed }" />
              <NavOverlay :is-nav-open="isNavOpen" />
            </main>
            <portal-target name="default-layout-footer" />
          </div>
        </template>
      </div>

      <portal-target name="tour-trigger" />

      <CalendlyModal
        v-if="scheduler.show"
        :event-path="calendlyEventPath"
        :guest-name="currentUser.profile.full_name"
        :guest-email="currentUser._auth.email"
        :answers="eventAnswers"
        :utm-content="eventUtmContent"
        :utm-medium="eventUtmMedium"
        @event-scheduled="onCallScheduled"
        @close="resetScheduler"
      />

      <!-- Terms of Service Modal that shows up if user logs in and has not accepted TOS -->
      <CoreTOS
        :close-on-submit="true"
        :show-modal="showTermsOfService"
        :show-close="false"
        @hide-modal="handleCoreTOS"
      />

      <LongStandingDelinquentModal
        v-if="showLongStandingDelinquent"
        @close="showLongStandingDelinquent = false"
      />

      <AdBlockerModal
        v-if="showAdBlockerModal"
        @close="showAdBlockerModal = false"
      />
      <portal-target class="relative z-40" name="drawer-portal" />
      <portal-target class="relative z-50 layout-portal" name="layout" />
      <IntervalUpgradeOffer
        v-if="
          !showTermsOfService &&
          userSavings.doesCustomerQualify &&
          showInAppUpgrade
        "
        :user-savings="userSavings"
      />

      <RootModal v-if="isRootModalOpen" />

      <!-- <BambeeDevTools v-if="!isProd" /> -->
      <EscalationsPopup />
      <BaseNotifications />
      <CookiePolicyBanner
        v-if="!showTermsOfService"
        class="fixed left-0 right-0 bottom-0 z-50"
      />
      <PlanSelectorModal
        v-if="shouldShowUpgradeModal"
        :recommended-package="recommendedPackage"
        @close="toggleUpgradeModal(false)"
      />
      <SupportModal
        v-if="showSupportModal"
        :action-options="supportOptions.action_options"
        :contact-options="supportOptions.contact_options"
        @close="closeSupportModal"
      />
      <ReactivatePlanModal
        v-if="shouldShowReactivateModal"
        @close="toggleReactivateModal(false)"
      />
      <PayrollRootModal v-if="isPayrollRootModalOpen" />
      <ModalDialog
        v-if="showHRMSupport"
        size="large"
        overlay="dark"
        @close="showHRMSupport = !showHRMSupport"
      >
        <CalendlyCard
          :url="currentCalendlyUrl"
          @event-scheduled="handleCallScheduled($event)"
        />
      </ModalDialog>
      <!-- <AIUpsellModal
        v-if="showAIUpsellModal"
        :is-loading="isAIUpsellLoading"
        loading-message="We're getting you set up."
        :has-upgraded="isAIOptedIn"
        :loading-thread="isLoading"
        @opt-in="handleOptIn"
        @close="closeAIUpsellModal"
        @explore="handleSendMessage('I want to create a new policy')" -->
      />
      <!-- <AiUpsellModals /> -->
    </ErrorBoundary>
  </div>
</template>

<script>
import AILaunchPadModal from '@/components/AILaunchPadModal.vue';
import NewBambeeModal from '@/components/NewBambeeModal.vue';
import BaseNotifications from '@/components/BaseNotifications/BaseNotifications.vue';
import CookiePolicyBanner from '@/modules/CookiePolicy/components/CookiePolicyBanner/CookiePolicyBanner.vue';
import ErrorBoundary from '@/modules/payroll/components/ErrorBoundary/ErrorBoundary.vue';
import { AnnularThrobber, ModalDialog, BaseButton } from '@bambeehr/pollen';
import format from 'date-fns/format';
import { detectAnyAdblocker } from 'just-detect-adblock';
import shortid from 'shortid';
import { mapGetters, mapMutations, mapState } from 'vuex';
import { mapActions } from 'pinia';
import { useCallsStore } from '@/store/calls';
import CoreTOS from '@/modules/OnboardingWizard/components/CoreTOS';
import NavOverlay from '@/components/default-layout/NavOverlay';
import TheHeader from '@/components/default-layout/TheHeader';
import TheNav from '@/components/default-layout/TheNav';
import CookieNames from '@/constants/CookieNames';
import FeatureFlags from '@/constants/FeatureFlags';
import Store from '@/constants/Store';
import { CallKinds } from '@/constants/calendly';
import { getCookie, setCookie } from '@/lib/cookie-helpers';
import {
  calendlyHrOverviewEvent,
  calendlySalesCookie,
} from '@/lib/globals/calendly';
import { generateSalesTeamCalendlySlug } from '@/utils/calendly-helpers';
import launchDarkly from '@bambeehr/vue-launch-darkly';
import GlowButton from '@/components/GlowButton/GlowButton.vue';
import ServiceUpdateBanner from '@/components/ServiceUpdates/ServiceUpdateBanner.vue';
import ServiceUpdateModal from '@/components/ServiceUpdates/ServiceUpdateModal.vue';
import GlobalDelinquentBanner from '@/components/banners/GlobalDelinquentBanner.vue';
import PausedAccountBanner from '@/components/banners/PausedAccountBanner.vue';
import { UserRoles } from '@/constants/users';
import useBambeeUpsell from '@/hooks/useBambeeUpsell/useBambeeUpsell';
import useCurrentUser from '@/hooks/useCurrentUser';
import useRouterHistory from '@/hooks/useRouterHistory/useRouterHistory';
import useServiceUpdate from '@/hooks/useServiceUpdates/useServiceUpdates';
import { BAMBEE_PLUS } from '@/lib/globals/routes';
import RootModal from '@/modules/OnLoginTasks/components/RootModal';
import useOnLoginTasks from '@/modules/OnLoginTasks/hooks/useOnLoginTasks';
import TierUpgradeNoticeBanner from '@/modules/StaffManagement/components/TierUpgradeNotice/TierUpgradeNoticeBanner.vue';
import TierUpgradeNoticeModal from '@/modules/StaffManagement/components/TierUpgradeNotice/TierUpgradeNoticeModal.vue';
import useTierUpgradeNotice from '@/modules/StaffManagement/components/TierUpgradeNotice/useTierUpgradeNotice';
import IntervalUpgradeOffer from '@/modules/TaskCenter/components/IntervalUpgradeOffer/IntervalUpgradeOffer.vue';
import useIntervalUpgradeOffer from '@/modules/TaskCenter/hooks/useIntervalUpgradeOffer/useIntervalUpgradeOffer';
import useSupportOptions from '@/modules/Team/Support/useSupportOptions';
import { isProd } from '@/utils/env-helper';
import { bucketTest } from '@/utils/splitTest/splitTest';
import {
  computed,
  defineComponent,
  ref,
  useContext,
  useRoute,
  watch,
  onMounted,
  reactive,
  useRouter,
} from '@nuxtjs/composition-api';
import usePayrollRootModal from '@/modules/payroll/components/PayrollRootModal/usePayrollRootModal';
import {
  payrollPlanSelectJourney,
  payrollMigrationExplainer,
} from '@/modules/payroll/components/PayrollRootModal/constants/componentConfig';
import useCompanyBillingInfo from '@/hooks/useCompanyBillingInfo';
import useCurrentPlan from '@/hooks/useCurrentPlan/useCurrentPlan';
import ContentfulExplainer from '@/components/ContentfulExplainer.vue';
import useStaffManagement from '@/modules/StaffManagement/hooks/useStaffManagement';
import { ContentfulEntryIDs } from '@/lib/globals/ContentfulEntryIDs';
import { useAdvisorsStore } from '@/store/advisors';
import useAIChatModal from '@/modules/AI/hooks/useAIChatModal';
import GlowModal from '@/components/GlowModal.vue';
import AIUpsellModal from '@/modules/AI/components/AIUpsellModal/AIUpsellModal.vue';
import AiModal from '@/components/AiModal';
import useAIUpsell from '@/modules/AI/hooks/useAIUpsell';
import AiUpsellModals from '@/components/AiUpsellModals.vue';
import useAIConversation, {
  Assistants,
} from '@/modules/AI/hooks/useAIConversation';

const SPLASH_PAGES = [
  BAMBEE_PLUS.path,
  `${BAMBEE_PLUS.path}/`,
  '/time-and-attendance',
];

const initList = [
  'Scanned millions of unique HR scenarios to customize your experience',
  'Built your HR foundation with expert-crafted policies and solutions',
  'Powered up your personalized HR platform for instant problem-solving',
  'Customized your HR platform with your company’s unique needs',
  'Analyzed your HR data to provide you with the best HR solutions',
];

export default defineComponent({
  components: {
    AiUpsellModals,
    BaseNotifications,
    ErrorBoundary,
    GlobalDelinquentBanner,
    AnnularThrobber,
    NavOverlay,
    TheHeader,
    TheNav,
    TierUpgradeNoticeModal,
    RootModal,
    ServiceUpdateModal,
    ServiceUpdateBanner,
    IntervalUpgradeOffer,
    CookiePolicyBanner,
    PausedAccountBanner,
    CoreTOS,
    ModalDialog,
    GlowModal,
    BaseButton,
    AIUpsellModal,
    AiModal,
    NewBambeeModal,
    AILaunchPadModal,
    PayrollRootModal: () =>
      import(
        '@/modules/payroll/components/PayrollRootModal/PayrollRootModal.vue'
      ),
    // BambeeDevTools: () => import('@/components/BambeeDevTools/BambeeDevTools'),
    AdBlockerModal: () => import('@/components/modals/AdBlockerModal'),
    CalendlyModal: () => import('@/components/scheduler/CalendlyModal'),
    CountdownBanner: () => import('@/components/banners/CountdownBanner'),
    LongStandingDelinquentModal: () =>
      import('@/components/modals/LongStandingDelinquentModal'),
    TierUpgradeNoticeBanner,
    CreditCardFailedModal: () =>
      import('@/components/CreditCardFailedModal/CreditCardFailedModal'),
    EscalationsPopup: () =>
      import('@/components/EscalationsPopup/EscalationsPopup'),
    PlanSelectorModal: () =>
      import('@/components/PlanSelectorModal/PlanSelectorModal.vue'),
    SupportModal: () => import('@/modules/Team/Support/SupportModal.vue'),
    ReactivatePlanModal: () =>
      import('@/components/ReactivatePlanModal/ReactivatePlanModal'),
    CalendlyCard: () => import('@/components/scheduler/CalendlyCard'), 
  },

  setup() {
    const { currentUser } = useCurrentUser();
    const route = useRoute();
    const router = useRouter();
    const { staffCount } = useStaffManagement();
    const { showAIChat, openAIChat, closeAIChat } = useAIChatModal();
    const {
      showAIUpsellModal,
      openAIUpsellModal,
      closeAIUpsellModal,
      optInAi,
      isLoading: isAIUpsellLoading,
      isAIOptedIn,
    } = useAIUpsell();
    const { handleSendMessage, isLoading } = useAIConversation();

    const showLaunchPad = ref(false);

    watch([isAIUpsellLoading, isAIOptedIn], ([loading, isOpted]) => {
      if (isOpted) {
        showAIUpsellModal.value = false;
      }

      if (!loading && isOpted) {
        showLaunchPad.value = true;
      }
    });

    const handleOptIn = () => {
      optInAi();
    };

    bucketTest();
    const userSavings = ref({});
    const { shouldShowBanner, openModal } = useTierUpgradeNotice();
    const { shouldShowInitally: shouldShowServiceUpdate, isBannerVisible } =
      useServiceUpdate();
    const {
      toggleUpgradeModal,
      toggleReactivateModal,
      shouldShowUpgradeModal,
      shouldShowReactivateModal,
      recommendedPackage,
    } = useBambeeUpsell();
    const { isPayrollTierFeatureEnabled } = useCompanyBillingInfo();
    const { isTierZero } = useCurrentPlan();

    const contentfulEntry = computed(() => {
      if (isTierZero.value && staffCount.value === 0) {
        return ContentfulEntryIDs.PAYROLL_INELIGIBLE_UPGRADE_EXPLAINER;
      }
      if (isTierZero.value && staffCount.value > 0) {
        return ContentfulEntryIDs.PAYROLL_TIER_ZERO_UPGRADE_EXPLAINER;
      }
    });

    const tierZeroJourney = reactive({
      id: 0,
      title: 'Plan Selector',
      component: ContentfulExplainer,
      modalProps: {
        overlay: 'dark',
        'overlay-close': true,
        size: 'large',
        'full-bleed': false,
      },
      componentProps: {
        entryId: contentfulEntry,
      },
    });

    watch(
      staffCount,
      (newVal) => {
        // Customers without staff are unable to purchase payroll.
        if (isTierZero.value && staffCount.value === 0) {
          payrollPlanSelectJourney.screens.unshift(tierZeroJourney);
        } else if (isTierZero.value && staffCount.value > 0) {
          payrollPlanSelectJourney.screens.unshift(tierZeroJourney);
        }
      },
      {
        immediate: true,
      }
    );

    const showTOSModal = ref(true);
    const handleCoreTOS = () => {
      showTOSModal.value = true;
    };

    const {
      shouldShowSupportModal,
      supportOptions,
      showCalendlyModal,
      isReady: isSupportReady,
      currentOption,
      showConfirmation,
      currentCalendlyUrl,
      showHRMSupport,
      handleCallScheduled,
    } = useSupportOptions();

    // Controls payroll related modals
    const { isPayrollRootModalOpen, loadJourney } = usePayrollRootModal();
    onMounted(() => {
      // Open Payroll Modal journey based on query params
      if (
        route.value?.query?.modal === 'payroll_plans' &&
        isPayrollTierFeatureEnabled.value
      ) {
        loadJourney(payrollPlanSelectJourney);
      }
      if (
        route.value?.query?.modal === 'new-plans' &&
        isPayrollTierFeatureEnabled.value
      ) {
        loadJourney(payrollMigrationExplainer);
      }
    });

    const showSupportModal = computed(
      () => shouldShowSupportModal.value || showCalendlyModal.value
    );

    const { shouldShowUpgradeOffer } = useIntervalUpgradeOffer();

    const { $axios: axios } = useContext();

    const { componentList, isRootModalOpen, openRootModal, userStatesList } =
      useOnLoginTasks();

    useRouterHistory();

    const fullBleed = computed(() => SPLASH_PAGES.includes(route.value.path));

    const checkUserSavings = async () => {
      try {
        const status = await axios?.post(
          '/billing/billing/v2/preview/upgrade',
          {},
          { baseURL: process.env.PUBLICAPI_URL }
        );
        userSavings.value = status?.data;
      } catch (e) {
        console.error(e);
      }
    };

    const showInAppUpgrade = computed(
      () =>
        currentUser.value?.role !== UserRoles.EMPLOYEE &&
        shouldShowUpgradeOffer(userStatesList.value)
    );

    if (showInAppUpgrade.value) {
      checkUserSavings();
    }

    watch(componentList, (value) => {
      if (value.length) {
        openRootModal();
      }
    });

    const closeSupportModal = () => {
      // Reset Support Modal state
      currentOption.value = {};
      currentCalendlyUrl.value = '';
      showCalendlyModal.value = false;
      showConfirmation.value = false;
      shouldShowSupportModal.value = false;
    };

    const advisorStore = useAdvisorsStore();
    const primaryAdvisor = computed(() => advisorStore.primaryAdvisor);

    const handleExitFromLaunchPad = () => {
      showLaunchPad.value = false;
      router.push('/dashboard');
    };

    const handleStartChat = (asst, prompt) => {
      handleSendMessage(prompt, asst);
      showLaunchPad.value = false;
    };

    return {
      primaryAdvisor,
      shouldShowBanner,
      openModal,
      shouldShowServiceUpdate,
      isBannerVisible,
      isRootModalOpen,
      isProd,
      userSavings,
      showInAppUpgrade,
      fullBleed,
      toggleUpgradeModal,
      toggleReactivateModal,
      shouldShowUpgradeModal,
      shouldShowReactivateModal,
      recommendedPackage,
      shouldShowSupportModal,
      supportOptions,
      isSupportReady,
      showCalendlyModal,
      showSupportModal,
      closeSupportModal,
      showTOSModal,
      handleCoreTOS,
      isPayrollRootModalOpen,
      currentCalendlyUrl,
      showHRMSupport,
      handleCallScheduled,
      showAIChat,
      openAIChat,
      closeAIChat,
      showAIUpsellModal,
      openAIUpsellModal,
      closeAIUpsellModal,
      optInAi,
      handleOptIn,
      isAIUpsellLoading,
      isAIOptedIn,
      handleSendMessage,
      isLoading,
      initList,
      showLaunchPad,
      handleStartChat,
      handleExitFromLaunchPad,
    };
  },
  data() {
    return {
      hasAdblocker: false,
      isNavOpen: false,
      scheduler: {
        show: false,
        type: null,
        callId: '',
        loading: false,
      },
      showAdBlockerModal: false,
      showCountdown: false,
      showExpandedNav: false,
      showLongStandingDelinquent: false,
      isServiceUpdateModalOpen: false,
    };
  },

  computed: {
    ...mapGetters({
      isOwner: Store.auth.Getters.IS_OWNER,
      pricePlans: 'pricePlans/all',
      scheduled: 'calls/ovCall',
      ticket: 'tickets/all',
      isCompanyAdmin: Store.auth.Getters.IS_COMPANY_ADMIN,
      isLongStandingDelinquent: Store.auth.Getters.IS_LONG_STANDING_DELINQUENT,
      company: 'company',
    }),
    ...mapState({
      calendlyLeadFired: 'calendlyLeadFired',
      currentUser: 'current_user',
      legacyScheduler: 'legacyScheduler',
      showTermsOfService: 'showTermsOfService',
    }),

    companyName() {
      return (this.company?.profile?.dba || '').trim() || this.company.name;
    },

    lockLongStandingDelinquent() {
      return (
        this.isCompanyAdmin &&
        (launchDarkly.getFlags()[FeatureFlags.LOCK_LONG_STANDING_DELINQUENTS] ||
          false)
      );
    },

    primaryAdvisorCalendlySlug() {
      return this.primaryAdvisor?.user?.calendlySlug;
    },

    calendlyEventPath() {
      if (this.scheduler.type !== 'sales') {
        return `${this.primaryAdvisorCalendlySlug}/${calendlyHrOverviewEvent}`;
      }

      return generateSalesTeamCalendlySlug(this.company, this.pricePlans);
    },
    eventAnswers() {
      return ['', `1${this.currentUser.profile.phone}`];
    },
    eventUtmContent() {
      return [
        this.company._id,
        this.currentUser._id,
        this.scheduler.callId || this.callId,
        CallKinds.OVERVIEW_CALL,
      ].join('|');
    },
    eventUtmCampaign() {
      return '';
    },
    eventUtmMedium() {
      return `1${this.currentUser.profile.phone}`;
    },

    company() {
      return this.currentUser._company || {};
    },

    probablyScheduled() {
      return (
        this.calendlyLeadFired ||
        getCookie(`${calendlySalesCookie}${this.company._id}`) ||
        false
      );
    },

    schedule() {
      if (this.scheduled) {
        return {
          date: format(this.scheduled.callScheduledFor, 'eeee MMM do'),
          time: format(this.scheduled.callScheduledFor, 'h:mm a'),
        };
      }

      return {
        date: null,
        time: null,
      };
    },
  },

  watch: {
    hasAdblocker(newVal) {
      this.showAdBlockerModal = !!(
        newVal && !getCookie(CookieNames.VIEWED_AD_BLOCKER_MODAL)
      );
    },
    $route() {
      this.isNavOpen = false;
    },
  },

  created() {
    this.fetchCalls({ companyId: this.company._id });
    // TODO(jon.jandoc): I have no idea why this is using `$store.subscribe`.
    // This seems like something that can be taken care of with some normal
    // watchers or computed properties.
    this.$store.subscribe((mutation, state) => {
      if (['resetScheduler', 'setSchedulerOn'].includes(mutation.type)) {
        this.scheduler.show = state.legacyScheduler.show;
        this.scheduler.type = state.legacyScheduler.type;
        this.scheduler.callId = shortid.generate();
      }
    });
  },

  async mounted() {
    // Show 60s countdown timer for franchise leads that clicked "Call Me Now"
    if (
      this.$route.query['immediate-request'] === 'true' &&
      localStorage.getItem('countdownCompleted') !== 'true'
    ) {
      this.showCountdown = true;
    }

    this.hasAdblocker = await detectAnyAdblocker();
    this.showLongStandingDelinquent =
      this.lockLongStandingDelinquent && this.isLongStandingDelinquent;
  },

  methods: {
    ...mapActions(useCallsStore, { fetchCalls: 'fetch' }),
    // TODO(jon.jandoc): Need to investigate if these store mutations need to
    // actually live in Vuex or if they can be local to this layout. If they're
    // keepers for Vuex, we need to convert them to actions so that we're not
    // directly committing mutations from components.
    ...mapMutations({
      setResetScheduler: 'resetScheduler',
      setCalendlyEvent: 'setCalendlyEvent',
      setSchedulerOn: 'setSchedulerOn',
      toggleTermsOfService: 'showTermsOfService',
    }),

    handleModalTrigger() {
      this.setSchedulerOn('sales');
    },

    resetScheduler() {
      this.scheduler.show = false;

      return this.setResetScheduler();
    },

    onCallScheduled() {
      this.scheduler.show = false;
      this.$toast.open({
        message: 'Call scheduled',
        type: 'is-success',
      });

      if (this.legacyScheduler.type === 'sales') {
        setCookie(`${calendlySalesCookie}${this.company._id}`, true);
        this.setCalendlyEvent('lead');
      }

      this.resetScheduler();
    },

    handleTermsClose() {
      this.toggleTermsOfService(false);
    },
  },
});
</script>

<style lang="scss">
@import '~assets/css/bambee/print-view.scss';
</style>

<style>
.main-content > * {
  @apply max-w-screen-2xl mx-auto w-full relative;
}

.main-content > .full-bleed {
  @apply max-w-none -mx-4 lg:-mx-6 -mb-8 w-auto;
}
</style>

<style lang="scss" scoped>
// TODO(jon.jandoc): Ignoring stylelint because we're going to entirely redo all this.
/* stylelint-disable */
@import '~assets/css/shared.scss';

@media print {
  @page {
    margin: 0;
  }
}

// TODO(jon.jandoc): We can remove all of the below once we move modals to the
// Pollen component.

.layout-portal ::v-deep .bam-modal {
  ::v-deep .modal-content {
    width: auto;
  }

  @media screen and (max-width: 768px) {
    &.is-fullpage {
      position: absolute;
    }

    &.is-fullpage ::v-deep .modal-content {
      bottom: 0;
      height: 100vh;
      left: 0;
      margin: 0;
      max-height: none;
      position: fixed;
      right: 0;
      top: 0;
      width: 100vw;
    }

    &.is-fullpage ::v-deep .modal-close {
      right: 0;
      top: 0;
    }

    &.is-fullpage ::v-deep .modal-close:before,
    &.is-fullpage ::v-deep .modal-close:after {
      background-color: $black-grey-4;
    }
  }

  @media screen and (max-height: 768px) {
    &.is-fullpage {
      position: absolute;
    }

    &.is-fullpage ::v-deep .modal-content {
      bottom: 0;
      height: 100vh;
      left: 0;
      margin: 0;
      max-height: none;
      position: fixed;
      right: 0;
      top: 0;
      width: 100vw;
    }

    &.is-fullpage ::v-deep .modal-close {
      right: 0;
      top: 0;
    }

    &.is-fullpage ::v-deep .modal-close:before,
    &.is-fullpage ::v-deep .modal-close:after {
      background-color: $black-grey-4;
    }
  }

  @media screen and (min-width: 769px) {
    &.is-fullpage ::v-deep .animation-content.modal-content {
      width: auto;
    }
  }
}

//Scheduler Modal Styles
.bam-modal.schedule-modal ::v-deep .modal-content {
  align-items: center;
  display: flex;
  height: 601px;
  max-height: calc(100vh - 4rem);
  max-width: 600px;
  padding: 0 1rem;
}

.bam-modal.schedule-modal ::v-deep .modal-content .schedule-container {
  height: 100%;
}

@media screen and (max-width: 475px) {
  .bam-modal.schedule-modal ::v-deep .modal-content {
    height: 100%;
    margin: 0;
    max-height: none;
    padding: 0;
    width: 100%;
  }

  .bam-modal.schedule-modal ::v-deep .modal-content .schedule-container {
    border-radius: 0;
    height: 100%;
  }

  //Make close out (x) button Black and more aligned

  .bam-modal.schedule-modal ::v-deep .modal-close.is-large {
    right: 1rem;
    top: 1rem;
    z-index: 3;
  }

  .bam-modal.schedule-modal ::v-deep .modal-close:before,
  .bam-modal ::v-deep .modal-close:after {
    background-color: #3f3e40;
  }
}

@media screen and (max-height: 600px) {
  .bam-modal.schedule-modal ::v-deep .modal-content {
    height: 100%;
    margin: 0;
    max-height: none;
    max-width: none;
    padding: 0;
    width: 100%;
  }

  .bam-modal.schedule-modal ::v-deep .modal-content .schedule-container {
    border-radius: 0;
    height: 100%;
    width: 100%;
  }

  //Make close out (x) button Black and more aligned

  .bam-modal.schedule-modal ::v-deep .modal-close.is-large {
    right: 1rem;
    top: 1rem;
    z-index: 3;
  }

  .bam-modal.schedule-modal ::v-deep .modal-close:before,
  .bam-modal ::v-deep .modal-close:after {
    background-color: #3f3e40;
  }
}
</style>
